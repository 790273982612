import { InternalAxiosRequestConfig } from 'axios';
import { formData, checksum } from '@arbor-steel/utils';

const getJson = (data: any) => {
  let json = data;
  if (data instanceof FormData) {
    json = formData.toJson(data);
    delete json.files;
  }

  return json;
};

export default {
  addHeader: (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.data) {
      const json = getJson(config.data);
      const sha1 = checksum.generate(json);

      if (sha1) {
        Object.assign(config.headers, { 'X-Checksum': sha1 });
      }
    }

    return config;
  },
};


import { Component, Watch } from 'vue-property-decorator';
import { BusinessUser } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class UsersInvite extends BaseComponent {
  fields: any[] = [];

  resendLoading = '';

  loading = false;

  get users() {
    return this.$store.getters['business/getUsers'];
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  get businessUsersFreeLimit() {
    const authUser = this.$store.getters['auth/identity'];
    const businessTypes = this.$store.getters['business/getTypes'];
    if (authUser && authUser.businesses && businessTypes.length) {
      const currentBusiness = authUser.businesses.find(
        (business: any) => business.id === this.selectedBusiness,
      );
      if (currentBusiness) {
        const currentBusinessType = businessTypes.find(
          (type: any) => type.id === currentBusiness.type,
        );
        if (currentBusinessType) {
          if (currentBusinessType.nameLower === 'education') {
            return 9;
          }
          return 5;
        }
      }
    }
    return '';
  }

  @Watch('selectedBusiness')
  async fetchBusinessUsers() {
    try {
      this.loading = true;
      await this.$store.dispatch('business/getUsers');
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.$store.dispatch('business/getRoles');
    this.$store.dispatch('business/getTypes');
    this.$store.dispatch('business/getUsers');
  }

  get pUsers() {
    return this.users.filter((user: BusinessUser) => user.status === 'accepted' || user.status === 'pending');
  }

  get businessRoles() {
    return this.$store.getters['business/getRoles'];
  }

  get showAddUser() {
    let count = this.fields.length;

    if (this.pUsers) {
      count += this.pUsers.length;
    }

    return count < 6;
  }

  addField() {
    this.fields.push({
      email: '',
      role: '',
      loading: false,
      reminderSent: false,
    });
  }

  removeField(index: number) {
    this.fields.splice(index, 1);
  }

  async inviteUser(index: number) {
    try {
      for (let i = 0; i < this.pUsers.length; i += 1) {
        if (this.pUsers[i].user) {
          if (this.pUsers[i].user.email === this.fields[index].email.toLowerCase()) {
            this.$buefy.dialog.alert({
              title: 'User already invited',
              message: 'User is already invited',
              type: 'is-danger',
            });
            return;
          }
        }
        if (this.pUsers[i].invite_user) {
          if (this.pUsers[i].invite_user.email === this.fields[index].email.toLowerCase()) {
            this.$buefy.dialog.alert({
              title: 'User already invited',
              message: 'User is already invited',
              type: 'is-danger',
            });
            return;
          }
        }
      }
      this.fields[index].loading = true;
      await this.$store.dispatch('business/inviteUser', {
        email: this.fields[index].email,
        role: this.fields[index].role,
      });
      await this.$store.dispatch('business/getUsers');
      this.$buefy.toast.open({
        message: 'User invited',
        position: 'is-bottom',
      });
      this.removeField(index);
    } catch (err) {
      this.fields[index].loading = false;
      this.handleError(err);
    }
  }

  async sendReminder(email: string, role: string) {
    try {
      this.resendLoading = email;
      await this.$store.dispatch('business/inviteUser', {
        email,
        role,
      });
      this.$buefy.toast.open({
        message: 'Reminder Sent',
        position: 'is-bottom',
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.resendLoading = '';
    }
  }
}

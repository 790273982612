
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class Modal extends BaseComponent {
  @Prop({ default: false }) value?: boolean;

  @Prop({ default: false }) destroyOnHide?: boolean;

  close() {
    this.$emit('input', this.value);
  }

  get pDestroyOnHide(): boolean {
    return this.destroyOnHide ?? false;
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';

const Step = {
  props: ['currentStep', 'step', 'label'],
  template: `<li :class="{ active: currentStep == step, completed: currentStep > step }">
    <a>
      <b-icon icon="check"></b-icon>
      <span class="n">{{ step }}</span>
      <span class="text">{{ $t(label) }}</span>
    </a>
  </li>`,
};

@Component({
  components: {
    Step,
  },
})
export default class OnboardingSteps extends Vue {
  @Prop({ default: 0 }) currentStep?: number;

  @Prop({ default: false }) isBusiness?: boolean;

  get pCurrentStep() {
    return this.currentStep;
  }

  get pIsBusiness() {
    return this.isBusiness;
  }
}

import { render, staticRenderFns } from "./ThirdPartyHistoryDocumentListItem.vue?vue&type=template&id=3ffd7312&scoped=true&"
import script from "./ThirdPartyHistoryDocumentListItem.vue?vue&type=script&lang=ts&"
export * from "./ThirdPartyHistoryDocumentListItem.vue?vue&type=script&lang=ts&"
import style0 from "./ThirdPartyHistoryDocumentListItem.vue?vue&type=style&index=0&id=3ffd7312&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffd7312",
  null
  
)

export default component.exports
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import storage from '@/storage';

import layouts from '@/layouts';

// import Home from '@/views/Home.vue';
import SignIn from '@/views/SignIn.vue';
import SignUp from '@/views/SignUp.vue';
import RegistrationSuccess from '@/views/RegistrationSuccess.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ForgotPasswordInstructions from '@/views/ForgotPasswordInstructions.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import TwoFactorAuth from '@/views/TwoFactorAuth.vue';
import EmailConfirmation from '@/views/EmailConfirmation.vue';
import IdentityVerification from '@/views/IdentityVerification.vue';
import Subscription from '@/views/subscription/Subscription.vue';
import SubscriptionSuccess from '@/views/subscription/SubscriptionSuccess.vue';

import Dashboard from '@/views/Dashboard.vue';

import Documents from '@/views/documents/Documents.vue';
import DocumentTags from '@/views/documents/DocumentTags.vue';
import DocumentTagsInfo from '@/views/documents/DocumentTagsInfo.vue';
import FileManager from '@/views/file-manager/FileManager.vue';
import FileManagerDocuments from '@/views/file-manager/FileManagerDocuments.vue';
import FileManagerRecent from '@/views/file-manager/FileManagerRecent.vue';
import FileManagerSearch from '@/views/file-manager/FileManagerSearch.vue';
import FileManagerWorkflows from '@/views/file-manager/FileManagerWorkflows.vue';
import FileManagerTags from '@/views/file-manager/FileManagerTags.vue';

import Contacts from '@/views/contact/Contacts.vue';
import CreateContact from '@/views/contact/CreateContact.vue';
import ViewContact from '@/views/contact/ViewContact.vue';
import EditContact from '@/views/contact/EditContact.vue';
import WorkflowInvite from '@/views/workflow/WorkflowInvite.vue';
import ViewContactWorkflows from '@/views/contact/ViewContactWorkflows.vue';
import ViewContactActivity from '@/views/contact/ViewContactActivity.vue';

import Workflows from '@/views/workflow/Workflows.vue';
import WorkflowsCompleted from '@/views/workflow/WorkflowsCompleted.vue';
import WorkflowRequests from '@/views/workflow/WorkflowRequests.vue';
import CreateWorkflow from '@/views/workflow/CreateWorkflow.vue';
import CreateTask from '@/views/task/CreateTask.vue';
import EditTask from '@/views/task/EditTask.vue';
import TaskInfo from '@/views/task/TaskInfo.vue';
import ViewTask from '@/views/task/ViewTask.vue';
import ViewAHTask from '@/views/task/ViewAHTask.vue';

import Tasks from '@/views/task/Tasks.vue';
import AssignedTasks from '@/views/task/AssignedTasks.vue';

import ViewWorkflowTasks from '@/views/workflow/ViewWorkflowTasks.vue';
import ViewWorkflowMessages from '@/views/workflow/ViewWorkflowMessages.vue';
import ViewWorkflowDocuments from '@/views/workflow/ViewWorkflowDocuments.vue';
import ViewWorkflowDocumentDetail from '@/views/workflow/ViewWorkflowDocumentDetail.vue';
import ViewWorkflowContacts from '@/views/workflow/ViewWorkflowContacts.vue';
import ViewWorkflowActivity from '@/views/workflow/ViewWorkflowActivity.vue';
import AHAcceptInvitation from '@/views/workflow/AHAcceptInvitation.vue';
import AHDocumentRequest from '@/views/workflow/AHDocumentRequest.vue';
import AHDocumentRequestHistory from '@/views/workflow/AHDocumentRequestHistory.vue';
import AHDocumentNewRequest from '@/views/workflow/AHDocumentNewRequest.vue';
import WorkflowNewDocumentRequest from '@/views/workflow/WorkflowNewDocumentRequest.vue';

import ThirdPartyAcceptInvitation from '@/views/third-party/ThirdPartyAcceptInvitation.vue';
import ThirdPartyDocumentRequest from '@/views/third-party/ThirdPartyDocumentRequest.vue';
import ThirdPartyDocumentRequestHistory from '@/views/third-party/ThirdPartyDocumentRequestHistory.vue';
import ThirdPartyDocumentNewRequest from '@/views/third-party/ThirdPartyDocumentNewRequest.vue';
import ThirdPartyDocumentList from '@/views/third-party/ThirdPartyDocumentList.vue';
import ThirdPartyUpload from '@/views/third-party/ThirdPartyUpload.vue';
import ViewThirdPartTask from '@/views/third-party/ViewThirdPartTask.vue';
import ThirdPartyIdentityVerification from '@/views/third-party/ThirdPartyIdentityVerification.vue';

import PendingContact from '@/views/PendingContact.vue';
import NotFoundComponent from '@/components/NotFoundComponent.vue';

import CreateTaskAssignToMe from '@/views/task/CreateTaskAssignToMe.vue';

import Reports from '@/views/report/Reports.vue';

import Settings from '@/views/setting/Settings.vue';
import UserActivity from '@/views/setting/UserActivity.vue';

import Support from '@/views/supports/Support.vue';

import Privacy from '@/views/Privacy.vue';
import Terms from '@/views/Terms.vue';
import CookiePolicy from '@/views/CookiePolicy.vue';
import Search from '@/views/Search.vue';
import ThankYou from '@/views/ThankYou.vue';

import store from '@/store';
import { identityVerificationStatus } from '@/utils/constants';
import { AuthState } from '@/store/states';
// import { ThirdPartyResponse } from '@/types';
import Users from '@/views/users/Users.vue';
import UsersInvite from '@/views/users/UsersInvite.vue';
import BusinessAcceptInvitation from '@/views/BusinessAcceptInvitation.vue';

Vue.use(VueRouter);

const isSubscribed = (state: AuthState) => {
  const subscribedBusiness = state.identity?.businesses?.find(
    (business) => business.subscription?.status === 'paid',
  );
  if (subscribedBusiness) {
    return true;
  }
  return state.identity?.subscription?.status === 'paid';
};

const getTitle = (title: string) => `${title} - Deklum`;

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    // component: Home,
    // meta: {
    //   title: 'Arborsteel Digital Identity Citadel',
    //   layout: layouts.DefaultLayout,
    //   secured: false,
    // },
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn,
    meta: {
      title: getTitle('Sign In'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
  },
  {
    path: '/register',
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: getTitle('Sign Up'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/accept',
    name: 'ThirdPartyAcceptInvitation',
    component: ThirdPartyAcceptInvitation,
    meta: {
      title: getTitle('Accept Invitation - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/request/new',
    name: 'ThirdPartyDocumentNewRequest',
    component: ThirdPartyDocumentNewRequest,
    meta: {
      title: getTitle('New Document Request - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/request',
    name: 'ThirdPartyDocumentRequest',
    component: ThirdPartyDocumentRequest,
    meta: {
      title: getTitle('Document Request - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/history',
    name: 'ThirdPartyDocumentRequestHistory',
    component: ThirdPartyDocumentRequestHistory,
    meta: {
      title: getTitle('Document Request History - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/verification',
    name: 'ThirdPartyIdentityVerification',
    component: ThirdPartyIdentityVerification,
    meta: {
      title: getTitle('Identity Verification - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/tasks/:id',
    name: 'ViewThirdPartTask',
    component: ViewThirdPartTask,
    meta: {
      title: getTitle('View Task - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
    /*
    beforeEnter: async (to, from, next) => {
      const { user } = storage;

      if (user) {
        const { token } = to.params;
        const resp = await store.dispatch('thirdParty/detail', token) as ThirdPartyResponse;

        if (resp.contact.email === user.email) {
          return next({ name: 'ViewAHTask' });
        }
      }

      return next();
    },
    */
  },
  {
    path: '/3p/:token/list',
    name: 'ThirdPartyDocumentList',
    component: ThirdPartyDocumentList,
    meta: {
      title: getTitle('Document List - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/3p/:token/upload',
    name: 'ThirdPartyUpload',
    component: ThirdPartyUpload,
    meta: {
      title: getTitle('Document Upload - Third Party'),
      layout: layouts.ThirdPartyLayout,
      secured: false,
    },
  },
  {
    path: '/business/users/:token/accept',
    name: 'BusinessAcceptInvitation',
    component: BusinessAcceptInvitation,
    meta: {
      title: getTitle('Business Invitation'),
      secured: false,
    },
  },
  {
    path: '/registration-success',
    name: 'RegistrationSuccess',
    component: RegistrationSuccess,
    meta: {
      title: getTitle('Registration Successful'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
    beforeEnter: (to, from, next) => {
      const { user } = storage;

      if (user?.email && !user?.confirmed) {
        return next();
      }

      return next({ name: from.name || '404' });
    },
  },
  {
    path: '/email-confirmation',
    name: 'EmailConfirmation',
    component: EmailConfirmation,
    meta: {
      title: getTitle('Email Confirmation'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
    beforeEnter: (to, from, next) => {
      const { confirmation } = to.query;

      if (confirmation) {
        return next();
      }

      return next({ name: from.name || '404' });
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: getTitle('Forgot Password'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
  },
  {
    path: '/forgot-password-instructions',
    name: 'ForgotPasswordInstructions',
    component: ForgotPasswordInstructions,
    meta: {
      title: getTitle('Forgot Password Instructions'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: getTitle('Reset Password'),
      layout: layouts.RegistrationLayout,
      secured: false,
    },
    beforeEnter: (to, from, next) => {
      const { code } = to.query;

      if (code) {
        return next();
      }

      return next({ name: from.name || '404' });
    },
  },
  {
    path: '/2fa',
    name: 'TwoFactorAuth',
    component: TwoFactorAuth,
    meta: {
      title: getTitle('Verify'),
      layout: layouts.OnboardingLayout,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/twoFactorAuth']) {
        return next();
      }

      return next({ name: 'Subscription', query: from.query });
    },
  },
  {
    path: '/verify-identity',
    name: 'IdentityVerification',
    component: IdentityVerification,
    meta: {
      title: getTitle('Verify Identity'),
      layout: layouts.OnboardingLayout,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/twoFactorAuth']) {
        return next({ name: 'TwoFactorAuth' });
      }

      const identityVerified = identityVerificationStatus
        .isVerified(store.state.auth.identity?.onfido);

      if (identityVerified) {
        let returnUrl;
        if (from.query.returnUrl) {
          if (typeof from.query.returnUrl === 'string') {
            returnUrl = from.query.returnUrl;
          } else {
            [returnUrl] = from.query.returnUrl;
          }
        }
        if (returnUrl) {
          return next(atob(returnUrl));
        }
        return next({ name: 'Dashboard' });
      }

      return next();
    },
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      title: getTitle('Subscription'),
      layout: layouts.OnboardingLayout,
    },
    beforeEnter: (to, from, next) => {
      if (to.query.expired || to.query.upgrade) {
        return next();
      }
      if (!isSubscribed(store.state.auth)) {
        return next();
      }
      const identityVerified = identityVerificationStatus
        .isVerified(store.state.auth.identity?.onfido);

      if (!identityVerified) { // 2fa is not done
        return next({ name: 'IdentityVerification' });
      }

      return next({ name: 'Dashboard' });
    },
  },
  {
    path: '/subscription/success',
    name: 'SubscriptionSuccess',
    component: SubscriptionSuccess,
    meta: {
      title: getTitle('Subscription Success'),
      layout: layouts.NoContainerLayout,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: getTitle('Dashboard'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      title: getTitle('Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/documents/tags',
    name: 'DocumentTags',
    component: DocumentTags,
    meta: {
      title: getTitle('Document Tags'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/documents/tags/info',
    name: 'DocumentTagsInfo',
    component: DocumentTagsInfo,
    meta: {
      title: getTitle('Document Tags Info'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager',
    name: 'FileManager',
    component: FileManager,
    meta: {
      title: getTitle('Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager/documents',
    name: 'FileManagerDocuments',
    component: FileManagerDocuments,
    meta: {
      title: getTitle('Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager/recent',
    name: 'FileManagerRecent',
    component: FileManagerRecent,
    meta: {
      title: getTitle('Recent Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager/search',
    name: 'FileManagerSearch',
    component: FileManagerSearch,
    meta: {
      title: getTitle('Search Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager/workflows',
    name: 'FileManagerWorkflows',
    component: FileManagerWorkflows,
    meta: {
      title: getTitle('Workflow Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/file-manager/tags',
    name: 'FileManagerTags',
    component: FileManagerTags,
    meta: {
      title: getTitle('Manage Tags - Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/new',
    name: 'CreateContact',
    component: CreateContact,
    meta: {
      title: getTitle('Create Contact'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/pending',
    name: 'PendingContact',
    component: PendingContact,
    meta: {
      title: getTitle('Pending Contacts'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/:id/edit',
    name: 'EditContact',
    component: EditContact,
    meta: {
      title: getTitle('Edit Contact'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/:id',
    name: 'ViewContact',
    component: ViewContact,
    meta: {
      title: getTitle('Contact'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/:id/workflows',
    name: 'ViewContactWorkflows',
    component: ViewContactWorkflows,
    meta: {
      title: getTitle('Contact Workflows'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts/:id/activity',
    name: 'ViewContactActivity',
    component: ViewContactActivity,
    meta: {
      title: getTitle('Contact Activity'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      title: getTitle('Contacts'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: getTitle('Users'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/users/invite',
    name: 'UsersInvite',
    component: UsersInvite,
    meta: {
      title: getTitle('Invite Users'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/new',
    name: 'CreateWorkflow',
    component: CreateWorkflow,
    meta: {
      title: getTitle('Create Workflow'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/new-assign-me',
    name: 'CreateTaskAssignToMe',
    component: CreateTaskAssignToMe,
    meta: {
      title: getTitle('Tasks - Assign to me'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/new',
    name: 'CreateTask',
    component: CreateTask,
    meta: {
      title: getTitle('Create Task'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      title: getTitle('My Tasks'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/assigned',
    name: 'AssignedTasks',
    component: AssignedTasks,
    meta: {
      title: getTitle('Assigned Tasks'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/info',
    name: 'TaskInfo',
    component: TaskInfo,
    meta: {
      title: getTitle('Task Info'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/:token/requests/:id',
    name: 'ViewAHTask',
    component: ViewAHTask,
    meta: {
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/:id',
    name: 'ViewTask',
    component: ViewTask,
    meta: {
      title: getTitle('Task'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/tasks/:id/edit',
    name: 'EditTask',
    component: EditTask,
    meta: {
      title: getTitle('Edit Task'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/completed',
    name: 'WorkflowsCompleted',
    component: WorkflowsCompleted,
    meta: {
      title: getTitle('Completed Workflows'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/requests',
    name: 'WorkflowRequests',
    component: WorkflowRequests,
    meta: {
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows',
    name: 'Workflows',
    component: Workflows,
    meta: {
      title: getTitle('Workflows'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/invite',
    name: 'WorkflowInvite',
    component: WorkflowInvite,
    meta: {
      title: getTitle('Invite to Workflow'),
      layout: layouts.DashboardLayout,
    },
  },
  // {
  //   path: '/workflows/:id',
  //   name: 'ViewWorkflow',
  //   component: ViewWorkflow,
  //   meta: {
  //     layout: layouts.DashboardLayout,
  //   },
  // },
  {
    path: '/workflows/:id',
    name: 'ViewWorkflowTasks',
    component: ViewWorkflowTasks,
    meta: {
      title: getTitle('Workflow Tasks'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/messages',
    name: 'ViewWorkflowMessages',
    component: ViewWorkflowMessages,
    meta: {
      title: getTitle('Workflow Messages'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/documents',
    name: 'ViewWorkflowDocuments',
    component: ViewWorkflowDocuments,
    meta: {
      title: getTitle('Workflow Documents'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/documents/:documentId',
    name: 'ViewWorkflowDocumentDetail',
    component: ViewWorkflowDocumentDetail,
    meta: {
      title: getTitle('Workflow Document'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/contacts',
    name: 'ViewWorkflowContacts',
    component: ViewWorkflowContacts,
    meta: {
      title: getTitle('Workflow Contacts'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/activity',
    name: 'ViewWorkflowActivity',
    component: ViewWorkflowActivity,
    meta: {
      title: getTitle('Workflow Activity'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:token/accept',
    name: 'AHAcceptInvitation',
    component: AHAcceptInvitation,
    meta: {
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:token/requests',
    name: 'AHDocumentRequest',
    component: AHDocumentRequest,
    meta: {
      title: getTitle('Document Request'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:token/requests/history',
    name: 'AHDocumentRequestHistory',
    component: AHDocumentRequestHistory,
    meta: {
      title: getTitle('Document Request History'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:token/requests/new',
    name: 'AHDocumentNewRequest',
    component: AHDocumentNewRequest,
    meta: {
      title: getTitle('New Document Request'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/workflows/:id/documents/requests/new',
    name: 'WorkflowNewDocumentRequest',
    component: WorkflowNewDocumentRequest,
    meta: {
      title: getTitle('New Document Request'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      title: getTitle('Reports'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/profile/activity',
    name: 'UserActivity',
    component: UserActivity,
    meta: {
      title: getTitle('My Activity'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: getTitle('Settings'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: getTitle('Support'),
      layout: layouts.DashboardLayout,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      layout: layouts.ContentLayout,
      secured: false,
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      layout: layouts.ContentLayout,
      secured: false,
    },
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy,
    meta: {
      layout: layouts.ContentLayout,
      secured: false,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      secured: false,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: getTitle('About'),
      layout: layouts.DefaultLayout,
      secured: false,
    },
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      title: getTitle('Thank You'),
      secured: false,
    },
  },
  {
    path: '*',
    name: '404',
    component: NotFoundComponent,
    meta: {
      title: getTitle('Page Not Found'),
      layout: layouts.NoContainerLayout,
      secured: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.API_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const state: AuthState = await store.dispatch('sync');

  if (to.matched.some((p) => p.meta?.secured !== false)) {
    if (!store.state.auth?.token && to.name !== 'Home') {
      console.log(to);
      return next({ name: 'Home', query: { returnUrl: btoa(to.fullPath) } });
    }
  }

  const twoFactorAuth: boolean = store.getters['auth/twoFactorAuth'];
  const identityVerified = identityVerificationStatus
    .isVerified(store.state.auth.identity?.onfido);

  if (to.meta?.layout === layouts.DashboardLayout) {
    if (!twoFactorAuth) { // 2fa is not done
      return next({ name: 'TwoFactorAuth' });
    }

    if (!isSubscribed(state)) {
      return next({ name: 'Subscription' });
    }

    if (!identityVerified) { // 2fa is not done
      return next({ name: 'IdentityVerification' });
    }

    next();
  }

  // if (store.state.auth?.token) {
  //   const twoFactorAuth: boolean = store.getters['auth/twoFactorAuth'];
  //   const identityVerified: boolean = store.state.auth.identity?.onfido
  //     && identityVerificationStatus.isVerified(store.state.auth.identity?.onfido.status);

  //   if (twoFactorAuth && identityVerified) {
  //     if (to.name === 'Dashboard') {
  //       return next();
  //     }
  //     return next({ name: 'Dashboard' });
  //   }

  //   if (twoFactorAuth) {
  //     if (to.name === 'IdentityVerification') {
  //       return next();
  //     }
  //     return next({ name: 'IdentityVerification' });
  //   }

  //   if (!twoFactorAuth) {
  //     if (to.name === 'TwoFactorAuth') {
  //       return next();
  //     }
  //     return next({ name: 'TwoFactorAuth' });
  //   }
  // }

  return next();
});

router.afterEach((to) => {
  document.title = to.meta?.title || 'Deklum - For better client journeys';
});

export default router;

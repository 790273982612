

import { Component } from 'vue-property-decorator';
import {
  IdentityResponse,
  IdentityStatusResponse,
  IdentityVerification,
} from '@/types';
import { actions } from '@/store';

import BaseComponent from './base-component';

@Component
export default class Onfido extends BaseComponent {
  // private tokenResponse?: Promise<IdentityResponse>;
  isThirdParty = false;

  showVerificationModal = false;

  sessionId = '';

  verificationUrl = '';

  /*
  created() {
    this.generateToken();
  }
  */

  get businesses() {
    if (this.$store.state.auth.identity) {
      return this.$store.state.auth.identity.businesses;
    }
    return [];
  }

  get isBusiness() {
    return !!(this.businesses && this.businesses.length);
  }

  onCloseModal() {
    this.showVerificationModal = false;
  }

  mounted() {
    if (this.$route.name === 'ThirdPartyIdentityVerification') {
      this.isThirdParty = true;
    }
    this.init(false);
  }

  async generateToken() {
    let result;
    if (this.isThirdParty) {
      result = await this.$store.dispatch('thirdParty/generateContactToken', {
        token: this.$route.params.token,
      });
    } else {
      result = await this.$store.dispatch(actions.IDENTITY_VERIFICATION_GENERATE_TOKEN);
    }
    return result;
  }

  async fetchStatus() {
    let result;
    if (this.isThirdParty) {
      result = await this.$store.dispatch('thirdParty/checkContactStatus', {
        token: this.$route.params.token,
      });
    } else {
      result = await this.$store
        .dispatch(actions.IDENTITY_VERIFICATION_CHECK_STATUS);
    }
    return result as IdentityVerification;
  }

  async updateStatus(payload: any) {
    let result;
    if (this.isThirdParty) {
      result = await this.$store.dispatch('thirdParty/updateContactStatus', {
        token: this.$route.params.token,
        ...payload,
      });
    } else {
      result = await this.$store.dispatch(actions.IDENTITY_VERIFICATION_UPDATE_STATUS, payload);
    }
    return result as IdentityStatusResponse;
  }

  redirectOnSuccess() {
    if (this.isThirdParty) {
      this.$store.commit('thirdParty/setHistoryDataFetched', false);
      this.$router.replace({ name: 'ThirdPartyDocumentRequestHistory' });
    } else {
      this.$router.replace({ name: 'Dashboard' });
    }
  }

  redirectOnCancel() {
    if (this.isThirdParty) {
      this.init(false);
    } else {
      this.$router.replace('/');
    }
  }

  async checkStatus() {
    try {
      if (!this.showVerificationModal) {
        this.$buefy.toast.open({
          message: 'Checking verification status...',
          position: 'is-bottom',
        });
      }
      const result = await this.fetchStatus();

      if (result.status === 'complete') {
        this.showVerificationModal = false;
        if (this.isThirdParty) {
          await this.$store.dispatch('thirdParty/onfidoSendDocuments', {
            token: this.$route.params.token,
          });
        }
        if (result.result && result.result === 'clear') {
          this.notify({
            message: this.$t('form.identityVerification.success.message') as string,
            position: 'is-bottom',
            type: 'is-info',
          });
          this.redirectOnSuccess();
        } else {
          this.$buefy.dialog.alert({
            title: this.$t('form.identityVerification.error.title') as string,
            message: this.$t('form.identityVerification.error.message') as string,
            confirmText: 'Retry',
            closeOnConfirm: true,
            onConfirm: () => {
              this.init(true);
            },
            canCancel: false,
          });
        }
      } else if (result.status === 'EXPIRED') {
        this.showVerificationModal = false;
        this.$buefy.dialog.alert({
          title: this.$t('form.identityVerification.expired.title') as string,
          message: this.$t('form.identityVerification.expired.message') as string,
          confirmText: 'Retry',
          closeOnConfirm: true,
          onConfirm: () => {
            this.init(true);
          },
          canCancel: false,
        });
      } else {
        setTimeout(() => {
          this.checkStatus();
        }, 5000);
      }
    } catch (error) {
      this.handleError(error, 'form.identityVerification.error.title');
    }
  }

  async init(isRetry: boolean) {
    if (!isRetry) {
      let verified: IdentityVerification;

      try {
        verified = await this.fetchStatus();
      } catch (error: any) {
        const message = this.parseMessage(error);

        this.$buefy.dialog.alert({
          title: this.$t('form.identityVerification.error.title') as string,
          message,
          confirmText: this.$t('retry') as string,
          closeOnConfirm: true,
          onConfirm: () => {
            this.init(true);
          },
          canCancel: true,
          onCancel: () => {
            this.redirectOnCancel();
          },
        });

        return;
      }

      if (verified.status === 'complete') {
        if (verified.result && verified.result === 'clear') {
          this.$buefy.dialog.alert({
            title: this.$t('form.identityVerification.heading') as string,
            message: this.$t('form.identityVerification.alreadyComputed') as string,
            type: 'is-success',
            onConfirm: () => {
              this.redirectOnSuccess();
            },
          });
          this.redirectOnSuccess();
        } else {
          this.$buefy.dialog.alert({
            title: this.$t('form.identityVerification.error.title') as string,
            message: this.$t('form.identityVerification.error.message') as string,
            confirmText: 'Retry',
            closeOnConfirm: true,
            onConfirm: () => {
              this.init(true);
            },
            canCancel: false,
          });
        }

        return;
      }
    }

    let resp: IdentityResponse;

    try {
      resp = await this.generateToken();
    } catch (error: any) {
      const message = this.parseMessage(error);

      this.$buefy.dialog.alert({
        title: this.$t('form.identityVerification.error.title') as string,
        message,
        confirmText: this.$t('retry') as string,
        closeOnConfirm: true,
        onConfirm: () => {
          this.init(true);
        },
      });

      return;
    }

    this.sessionId = resp?.sessionId;
    this.verificationUrl = resp?.url;
    this.showVerificationModal = true;
    setTimeout(() => {
      this.checkStatus();
    }, 5000);
  }
}
